import { ResourceType } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetSiteById } from './hooks/useIsNewTsdb';

// Takes an array and converts it into object where keys are extracted from
// each item by given selector
export const mapArrayToObject = (data: any[], selector = 'id') =>
  data.reduce((result, item) => {
    const key = item[selector];
    return Object.assign(result, { [key]: item }, {});
  }, {});

// Almost the same as MapArrayToObject
// multiple items may share the same key
export const arrayToArrayMap = (data: any[], selector) =>
  data.reduce((result, item) => {
    const key = item[selector];
    const children = result[key];
    return {
      ...result,
      [key]: children ? [...children, item] : [item],
    };
  }, {});

// Map all values of the object with given function
export const mapValues = (data: Object, f: (value: any) => any) =>
  Object.keys(data).map((key) => f(data[key]));

// Returns values of given object
export const values = (obj: Object) => Object.keys(obj).map((i) => obj[i]);

export const createIterator = (start = 0, end = Infinity, step = 1) => {
  let index = start;
  let stepCount = 0;

  const iterator = {
    next: () => {
      if (index < end) {
        index += step;
        stepCount++;
      }
      return index < end;
    },
    getCurrent: () => index,
    getStepCount: () => stepCount,
    isIterable: () => index < end,
  };
  return iterator;
};

export interface UserName {
  firstName: string;
  lastName: string;
}

export const fullName = ({ firstName, lastName }: UserName): string =>
  [firstName, lastName].filter((n) => !!n).join(' ');

export const initials = ({ firstName, lastName }: UserName): string => {
  let result = '';
  result += firstName && firstName[0];
  result += lastName && lastName[0];

  return result.length > 0 ? result : '?';
};

export const hasSubstr = (s: string, q: string) =>
  (s || '').toLowerCase().indexOf((q || '').toLowerCase()) !== -1;

export const hasKeys = (o: { [k: string]: any }) =>
  Object.keys(o || {}).length > 0;

// Immutable version of "delete object.key"
export const dropKey = (o: { [_: string]: any }, k: string) =>
  Object.keys(o || {}).reduce((result, key) => {
    if (key !== k) result[key] = o[key];
    return result;
  }, {});

export interface GenericErrors {
  [k: string]: string[];
}

// Splits given list using given boolean predicate
// Returns array where first element contains all posivite outputs and seoncs element all negative
// export const split = (pred: (any) => boolean, data: any[]) => {
//   const { true: positive = [], false: negative = [] } = R.groupBy(
//     v => pred(v).toString(),
//     data
//   );

//   return [positive, negative];
// };

// Shallow replaces null values in any Object with undefined
export function replaceNullValues<T>(obj): T {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc[key] = value || undefined;
    return acc;
  }, {} as T);
}

// only handles common case, don't use for things like beach -> beaches
export const checkCommonPlural = (entity: string, count: number) => {
  if (count !== 1) return `${count} ${entity}s`;
  return `1 ${entity}`;
};

// from https://flyingsky.github.io/2018/01/26/javascript-detect-chinese-japanese/
export const REGEX_CHINESE =
  /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;

export const shortenString = (
  string: string | undefined | null,
  length: number,
  ellipsis: string = '...'
) => {
  if (!isDefined(string)) return '';

  const hasChinese = REGEX_CHINESE.test(string);
  if (hasChinese) {
    // Chinese characters are counted as 2 letters here, for the sake of more accurate space estimation
    let charCount = 0,
      lastCharIndex = 0;
    const keepWholeString = [...string].every((char, index) => {
      if (REGEX_CHINESE.test(char)) {
        charCount += 2;
      } else {
        charCount++;
      }
      if (charCount <= length) {
        lastCharIndex = index;
        return true;
      } else {
        return false;
      }
    });
    // Need to subtract 2 to account for ellipsis added, if ellipsis is ...
    return keepWholeString
      ? string
      : `${string.substring(
          0,
          lastCharIndex + 1 - Math.ceil(ellipsis.length / 2)
        )}${ellipsis}`;
  }

  if (string.length <= length) return string;
  // Need to subtract 3 to account for ellipsis added, if ellipsis is ...
  return `${string.substring(0, length - ellipsis.length)}${ellipsis}`;
};

export function formatNumber(x: number, decimalPoints: number) {
  const str = x.toFixed(decimalPoints);
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function sanitizeApiLoadingState(value: boolean | undefined) {
  if (value === undefined || value) return true;
  return false;
}

export function average(values: number[]) {
  const sum = values.reduce(function (sum, value) {
    return sum + value;
  }, 0);

  const avg = sum / values.length;
  return avg;
}

export function standardDeviation(values: number[]) {
  const avg = average(values);

  const squareDiffs = values.map(function (value) {
    const diff = value - avg;
    const sqrDiff = diff * diff;
    return sqrDiff;
  });

  const avgSquareDiff = average(squareDiffs);

  const stdDev = Math.sqrt(avgSquareDiff);

  return stdDev;
}

export function toCamelCase(str) {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

type NoInfer<T> = [T][T extends any ? 0 : never];

// MUST PROVIDE TYPE PARAMETERS FOR THIS FUNCTION TO WORK
// THIS IS TO PREVENT SENDING INCORRECT QUERY PARAMETERS TO THE API
// https://stackoverflow.com/questions/56687668/a-way-to-disable-type-argument-inference-in-generics
export const formatAPIFilters = <T = never>(filters?: NoInfer<T>): string => {
  if (filters === undefined) return '';
  const queryParams = new URLSearchParams();
  Object.keys(filters!).forEach((property) => {
    const filterValue = filters?.[property];
    if (filterValue === undefined || filterValue === null) return;
    if (Array.isArray(filterValue)) {
      if (filterValue.length > 0) {
        queryParams.set(property, filterValue.join(','));
      }
    } else queryParams.set(property, filterValue);
  });

  return queryParams.toString();
};

export const calcTwoPossibilyUndefinedValues = (
  v1: number | undefined,
  v2: number | undefined,
  operation: 'ADD' | 'SUBTRACT' | 'MULTIPLY' | 'DIVIDE' | 'MIN' | 'MAX'
) => {
  if (v1 === undefined || v2 === undefined) {
    if (v2 && operation !== 'DIVIDE') return v2;
    return v1;
  }

  switch (operation) {
    case 'ADD':
      return v1 + v2;
    case 'SUBTRACT':
      return v1 - v2;
    case 'MULTIPLY':
      return v1 * v2;
    case 'DIVIDE':
      return v2 !== 0 ? v1 / v2 : v1;
    case 'MIN':
      return Math.min(v1, v2);
    case 'MAX':
      return Math.max(v1, v2);
    default:
      return undefined;
  }
};

export const modifyApiResourceFilter = <T>(
  resourceIds: number[],
  resourceType: ResourceType,
  filters: T
): T => {
  switch (resourceType) {
    case ResourceType.SITE:
      return { ...filters, siteIds: resourceIds };
    case ResourceType.SENSOR:
      return { ...filters, sensorIds: resourceIds };
    case ResourceType.EQUIPMENT:
      return { ...filters, equipmentIds: resourceIds };
    case ResourceType.SPACE:
      return { ...filters, spaceIds: resourceIds };
    default:
      return filters;
  }
};

export const onSelectFilter = (
  setFilterIds: (selectedIds: number[]) => void,
  entityId: number,
  selectedIds: number[]
) => {
  const indexToRemove = selectedIds.indexOf(entityId);
  if (indexToRemove > -1) {
    setFilterIds([
      ...selectedIds.slice(0, indexToRemove),
      ...selectedIds.slice(indexToRemove + 1),
    ]);
  } else {
    setFilterIds([...selectedIds, entityId]);
  }
};

const NUM_FEET_IN_ONE_METER = 3.28084;

export const convertFeetToMeters = (
  measurement: number,
  numDecimals: number
) => {
  const inMeters = measurement / NUM_FEET_IN_ONE_METER;
  return Number.parseFloat(inMeters.toFixed(numDecimals));
};

export const convertMetersToFeet = (
  measurement: number,
  numDecimals: number
) => {
  const inFeet = measurement * NUM_FEET_IN_ONE_METER;
  return Number.parseFloat(inFeet.toFixed(numDecimals));
};

export const getDataArrayMinMax = <T>(data: T[], comparisonKeys: string[]) => {
  const result = {
    min: {} as any,
    max: {} as any,
  };
  comparisonKeys.forEach((dataKey) => {
    result.max[dataKey] = Number.MIN_SAFE_INTEGER;
    result.min[dataKey] = Number.MAX_SAFE_INTEGER;
  });

  data.forEach((datapoint) => {
    comparisonKeys.forEach((dataKey) => {
      const value = datapoint[dataKey];
      if (typeof value !== 'number') return;
      if (value < result.min[dataKey]) {
        result.min[dataKey] = value;
      }
      if (value > result.max[dataKey]) {
        result.max[dataKey] = value;
      }
    });
  });
  return result;
};

export const changeIncidentByPriority = (data: any) => {
  data.map((item) => {
    if (item.incidentPriority === 'NORMAL') {
      item.incidentPriority = 'LOW';
    } else if (item.incidentPriority === 'HIGHEST') {
      item.incidentPriority = 'CRITICAL';
    }
  });
  return data;
};

export const capitalizeFirstLetter = (string: string) => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

export const useUrlState = <T extends number | string | number[] | string[]>(
  stateKey: string,
  initialState: T
): [T, (newState: T) => void] => {
  const [urlState, setUrlState] = useState(initialState);
  const history = useHistory();

  useEffect(() => {
    let parsedValue = getUrlStateParams(history, stateKey, initialState);
    if (parsedValue) {
      if (Array.isArray(parsedValue)) {
        if (
          typeof parsedValue[0] === 'string' &&
          !isNaN(parseInt(parsedValue[0]))
        ) {
          //@ts-ignore
          parsedValue = parsedValue.map(Number);
        }
      }
      setUrlState(parsedValue);
    }
  }, []);

  const update = useCallback(
    (newState: T) => {
      setUrlState(newState);
      updateUrlState(history, stateKey, newState);
    },
    [history, stateKey]
  );

  return [urlState as T, update];
};
export const getUrlStateParams = <
  T extends string | number | string[] | number[]
>(
  history,
  stateKey: string,
  initialState: T
): T => {
  const searchParams = new URLSearchParams(history?.location?.search);
  // array processing
  if (Array.isArray(initialState)) {
    if (typeof initialState[0] === 'number') {
      return searchParams.getAll(stateKey).map(Number) as T;
    }
    return searchParams.getAll(stateKey) as T;
  }
  // single number or string value
  const value = searchParams.get(stateKey) as T;

  //@ts-ignore
  if (typeof initialState === 'number') return +value;

  return value || initialState;
};

export const updateUrlState = <T extends string | number | string[] | number[]>(
  history,
  stateKey: string,
  newState: T
) => {
  const pathname = history?.location?.pathname;
  const updatedSearchParams = new URLSearchParams(history?.location?.search);
  // add array to url param
  if (Array.isArray(newState)) {
    updatedSearchParams.delete(stateKey);
    newState.forEach((value) => {
      updatedSearchParams.append(stateKey, value);
    });
    history?.push({ pathname, search: updatedSearchParams.toString() });
    return;
  }
  // add single value to url param.
  if (!newState) {
    updatedSearchParams.delete(stateKey);
  } else updatedSearchParams.set(stateKey, `${newState}`);
  history?.push({ pathname, search: updatedSearchParams.toString() });
};

export const DEFAULT_PAGINATION_ROWS_COUNT = 100;

// this function decide if the installer test is running on a site or not.
export const hasInstallerTestResults = (testResults) => {
  return (
    testResults && Object.values(testResults).some((value) => Boolean(value))
  );
};

// to check if the page is a site level or not
export const isInSitesPage = () => {
  const currentPath = window.location.pathname;
  const pattern = /\/sites\/\d+\//;
  const hasPattern = pattern.test(currentPath);

  return hasPattern;
};

//converts a timestamp into a particular date format
export const formatDate = (timestamp: number, timezone: string): string => {
  const date = new Date(timestamp);

  // Create an Intl.DateTimeFormat object with the specified timezone
  const options: Intl.DateTimeFormatOptions = {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  const formattedParts = formatter.formatToParts(date);

  // Extract formatted parts
  const year = formattedParts.find((part) => part.type === 'year')?.value;
  const month = formattedParts.find((part) => part.type === 'month')?.value;
  const day = formattedParts.find((part) => part.type === 'day')?.value;

  return `${year}-${month}-${day}`;
};

export const currentDateString = (timeZone: string): string => {
  const currentDate = new Date();
  const options: Intl.DateTimeFormatOptions = {
    timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  const formattedDate = new Intl.DateTimeFormat('en-CA', options).format(
    currentDate
  );
  return formattedDate.split('/').reverse().join('-');
};

// Venstar heatpumps terminal data in time-series database
// before edge app 2.4 is incorrect and needs to be hidden forever
export const skipDataBasedOnTime = (
  time: number | string,
  edgeAppDate: string
): boolean => {
  const currentDate = new Date(time);
  const targetDate = new Date(edgeAppDate);
  return currentDate < targetDate;
};

const numberToMonthMap = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export const formatDateToWords = (dateString) => {
  const [year, month, day] = dateString.split('-');

  // Combine the parts into the desired format
  return `${numberToMonthMap[Number(month)]} ${day}, ${year}`;
};

export const convertToHoursAndMinutes = (decimal: number): string => {
  const hours = Math.floor(decimal);
  const minutes = Math.floor((decimal - hours) * 60);

  // Return the formatted string
  return `${hours}h ${minutes}m`;
};

export function handlePlatformSwitch(e, accessToken, platformUrl) {
  e.preventDefault(); // Prevent default link behavior
  const redirectUrl = `${platformUrl}#accessToken=${accessToken}`;
  window.location.replace(redirectUrl); // Use replace to prevent adding it to the history stack
}

export function handlePlatformSwitchToSop(e, accessToken, platformUrl) {
  e.preventDefault(); // Prevent default link behavior
  const redirectUrl = `${platformUrl}#accessToken=${accessToken}&location=sop`;
  window.location.replace(redirectUrl);
}
